import React from "react"

import Layout from "../components/layout"
import DisplayOnlyPage from "../components/displayonlypage"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const ProjectsPage = (props) => {
  return (
    <Layout>
      <SEO title="Projects" />
      <DisplayOnlyPage pages={props.data.allMarkdownRemark.edges} />
    </Layout>
  )
}

export default ProjectsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {fileAbsolutePath: {regex: "\/pages/projects.md/"}}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
